// 收文
export const receivedColumns = [
    {
        title: '办文号',
        dataIndex: 'serialNumber',     
        ellipsis: true
    },
    {
        title: '来文标题',
        dataIndex: 'archivedTitle',
        scopedSlots: { customRender: 'docTitle' },
        ellipsis: true
    },
    {
        title: '来文字号',
        dataIndex: 'documentNumber',
        ellipsis: true
    },
    {
        title: '紧急程度',
        dataIndex: 'urgencyDegree',
        // width: 100,
        scopedSlots: { customRender: 'urgencyDegree' },
    },
    {
        title: '密级程度',
        dataIndex: 'secrecyDegree',
        // width: 100,
        scopedSlots: { customRender: 'secrecyDegree' },
    },
    {
        title: '类型',
        dataIndex: 'documentType',
        // width: 150,
        scopedSlots: { customRender: 'documentType' },
    },
    {
        title: '经办人',
        dataIndex: 'archivedUser',
        // width: 120,
        scopedSlots: { customRender: 'archivedUser' },
    },
    {
        title: '归档时间',
        dataIndex: 'archivedTime',
        // width: 150,
        scopedSlots: { customRender: 'acceptTime' },
    },
    {
        title: '操作',
        // fixed:'right',
        dataIndex: 'action',
        // width: 240,
        scopedSlots: { customRender: 'action' },
    },
]

// 发文
export const sendingColumns = [
    {
        title: '发文字号',
        dataIndex: 'documentNumber',
        ellipsis: true
    },
    {
        title: '文稿标题',
        dataIndex: 'archivedTitle',
        ellipsis: true,
        scopedSlots: { customRender: 'docTitle' },
    },
    {
        title: '紧急程度',
        dataIndex: 'urgencyDegree',
        // width: 100,
        scopedSlots: { customRender: 'urgencyDegree' },
    },
    {
        title: '密级程度',
        dataIndex: 'secrecyDegree',
        // width: 100,
        scopedSlots: { customRender: 'secrecyDegree' },
    },
    {
        title: '类型',
        dataIndex: 'documentType',
        // width: 150,
        scopedSlots: { customRender: 'documentType' },
    },
    {
        title: '拟稿人',
        dataIndex: 'archivedUser',
        // width: 120,
        scopedSlots: { customRender: 'archivedUser' },
    },
    {
        title: '归档时间',
        dataIndex: 'archivedTime',
        // width: 150,
        scopedSlots: { customRender: 'acceptTime' },
    },
    {
        title: '操作',
        // fixed:'right',
        dataIndex: 'action',
        // width: 240,
        scopedSlots: { customRender: 'action' },
    },
]